import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import {
    fiatCurrencyList,
    getDateTextPicker,
    getStatus, useJunoCommonFunctions
} from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'
import FocusTrap from 'focus-trap-react'
import { DayPicker } from 'react-day-picker'
import ClearIcon from '@mui/icons-material/Clear'
import { CryptoTransactionTypes, CurrencyTypes, TransactionTypes, ValidStatus } from '../../constant'
import { isSameDay } from 'date-fns'
import { displayCurrencyPage } from '../../../../Redux/actions/junoAction'

export default function JncTransactionFilters({ filter, setFilter }) {
    const dispatch = useDispatch()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const clientData = useSelector((state) => state.juno.clientData)
    const balancesFiatData = useSelector((state) => state.juno.balancesCurrencies)
    const balancesCryptoData = useSelector((state) => state.juno.balancesCrypto)
    const balancesData = {
        fiat: balancesFiatData,
        crypto: balancesCryptoData,
    }
    const filteredBalancesData = (clientData.cryptoTab === "Enable" && clientData.fiatTab === "Enable")
        ? [...balancesFiatData, ...balancesCryptoData] : balancesData[activeCurrencyPage];
    const currencyListOptions = filteredBalancesData.map((x) => x.currencyShortName)
    const {getCryptoBalances, getBalances} = useJunoCommonFunctions()

    const popperRef = useRef(null)
    const buttonRef = useRef(null)
    const [popperElement, setPopperElement] = useState(null)
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start',
    })
    const [isPopperOpen, setIsPopperOpen] = useState(false)

    const getCurrencyTypes = () => {
        const types = []
        if (clientData.cryptoTab === "Enable") {
            types.push('Crypto')

        }
        if (clientData.fiatTab === "Enable") {
            types.push('Fiat')
        }
        return types
    }
    useEffect(() => {
        getCryptoBalances()
        getBalances()
    }, [])
    const closePopper = () => setIsPopperOpen(false)
    const handleDateClick = () => setIsPopperOpen(true)

    const handleDateSelect = (range) => {
        if (!range) return
        if (isSameDay(range.from, range.to)) {
            // Selected a single date
            setFilter((prev) => ({ ...prev, from: range.from, to: '' }))
        } else {
            // Selected a date range
            setFilter((prev) => ({
                ...prev,
                from: range.from,
                to: range.to
            }))
        }
    }

    const handleClearFilters = () => {
        const defaultFilter = {
            currency: [],
            currencyType: [''],
            type: '',
            from: '',
            to: '',
            status: '',
            page: 1,
            pageSize: window.innerWidth > 991 ? 10 : 5,
        }
        setFilter(defaultFilter)
    }

    function getCurrencyIcon(name) {
        const currency = filteredBalancesData.find((x) => x.currencyShortName === name)
        return currency ? currency.currencyIcon : null
    }

    const getTransactionTypes = () => {
        let options = [];
        const storedSettings = JSON.parse(localStorage.getItem("tabSettings")) || {};
        const { cryptoTab, fiatTab } = storedSettings;
        
        if (cryptoTab === "Enable" && fiatTab === "Enable") {
            options = [...TransactionTypes, ...CryptoTransactionTypes];
        } else if (cryptoTab === "Enable") {
            options = [...CryptoTransactionTypes];
        } else if (fiatTab === "Enable") {
            options = [...TransactionTypes];
        }
    
        return options;
    };

    return (
        <div className="jncTable-inputs">
            <div className="jncTransactions__filters">
                <div className="jncTable-inputs-group">
                    <div
                        ref={popperRef}
                        className="jncTable-inputs-group jncTable-inputs-btnGroup jncTable-inputs-group-text"
                    >
                        <button
                            data-e2e="date"
                            type="button"
                            className="theme-modal-field-input jncTable-inputs-btn"
                            aria-label="Pick a date"
                            onClick={handleDateClick}
                            ref={buttonRef}
                        >
                            {getDateTextPicker({
                                from: filter.from,
                                to: filter.to,
                            })}
                        </button>
                        {getDateTextPicker({
                            from: filter.from,
                            to: filter.to,
                        }) == 'Date' ? (
                            <svg
                                data-e2e="close-icons"
                                className="jncTable-inputs-group-icon"
                                width="12"
                                height="7"
                                viewBox="0 0 12 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.69922 0.700049C0.882553 0.516715 1.11588 0.425049 1.39922 0.425049C1.68255 0.425049 1.91589 0.516715 2.09922 0.700049L5.99922 4.60005L9.89922 0.700048C10.0826 0.516715 10.3159 0.425049 10.5992 0.425049C10.8826 0.425049 11.1159 0.516715 11.2992 0.700048C11.4826 0.883382 11.5742 1.11671 11.5742 1.40005C11.5742 1.68338 11.4826 1.91672 11.2992 2.10005L6.69922 6.70005C6.59922 6.80005 6.49089 6.87105 6.37422 6.91305C6.25755 6.95505 6.13255 6.97572 5.99922 6.97505C5.86589 6.97505 5.74089 6.95405 5.62422 6.91205C5.50755 6.87005 5.39922 6.79938 5.29922 6.70005L0.69922 2.10005C0.515886 1.91672 0.424218 1.68338 0.424218 1.40005C0.424218 1.11672 0.515886 0.883382 0.69922 0.700049Z"
                                    fill="#667085"
                                />
                            </svg>
                        ) : (
                            <ClearIcon
                                className="jncTable-inputs-group-icon clear-icon"
                                onClick={() =>
                                    setFilter((pre) => ({
                                        ...pre,
                                        from: '',
                                        to: '',
                                    }))
                                }
                            />
                        )}
                        {isPopperOpen && (
                            <FocusTrap
                                active
                                focusTrapOptions={{
                                    initialFocus: false,
                                    allowOutsideClick: true,
                                    clickOutsideDeactivates: true,
                                    onDeactivate: closePopper,
                                    fallbackFocus: buttonRef.current,
                                }}
                            >
                                <div
                                    tabIndex={-1}
                                    style={popper.styles.popper}
                                    className="dialog-sheet"
                                    {...popper.attributes.popper}
                                    ref={setPopperElement}
                                    role="dialog"
                                    aria-label="DayPicker calendar"
                                >
                                    <DayPicker
                                        initialFocus={isPopperOpen}
                                        mode="range"
                                        selected={{
                                            from: filter.from,
                                            to: filter.to,
                                        }}
                                        onSelect={handleDateSelect}
                                        fromYear={2022}
                                        toYear={new Date().getFullYear()}
                                        captionLayout="dropdown"
                                    />
                                </div>
                            </FocusTrap>
                        )}
                    </div>
                </div>
                <div className="jncTable-inputs-group jncTable-inputs-group-text">
                    <Typeahead
                        id="basic-typeahead-multiple-currency-type"
                        labelKey={(option) => `${option}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, currencyType: val }))
                        }
                        options={getCurrencyTypes()}
                        placeholder="Currency Type"
                        selected={filter.currencyType}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                    />
                </div>
                <div className="jncTable-inputs-group jncTable-inputs-group-text">
                    <Typeahead
                        id="basic-typeahead-multiple-currency"
                        data-e2e="currency"
                        labelKey={(option) => `${option}`}
                        onChange={(val) => {
                            setFilter((pre) => ({ ...pre, currency: val }))
                        }}
                        options={currencyListOptions}
                        placeholder="Currencies"
                        selected={filter.currency}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                        renderMenuItemChildren={(option) => (
                            <div className="jncTypeahead-item">
                                {getCurrencyIcon(option) && (
                                    <img
                                        src={`${imageBaseUrl}${getCurrencyIcon(option)}`}
                                    />
                                )}
                                <p data-e2e={option}>{option}</p>
                            </div>
                        )}
                    />
                </div>
                <div className="jncTable-inputs-group jncTable-inputs-group-text">
                    <Typeahead
                        id="basic-typeahead-multiple-status"
                        labelKey={(option) => `${getStatus(option)}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, status: val }))
                        }
                        options={ValidStatus}
                        placeholder="Status"
                        selected={filter.status || []}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                    />
                </div>
                <div className="jncTable-inputs-group jncTable-inputs-group-text" data-e2e="type">
                    <Typeahead
                        id="basic-typeahead-multiple-type"
                        labelKey={(option) => `${option}`}
                        onChange={(val) =>
                            setFilter((pre) => ({ ...pre, type: val }))
                        }
                        options={getTransactionTypes()}
                        placeholder="Type"
                        selected={filter.type || []}
                        clearButton={true}
                        filterBy={(option, props) => {
                            return option != ''
                        }}
                    />
                </div>
            </div>
            <button
                data-e2e="clear-filter"
                className="jncTransactions__clearFilter jncTable-inputs-group-text"
                onClick={handleClearFilters}
            >
                <svg
                    data-e2e="clear-all"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.12246 16.4733C9.12174 17.3603 11.503 17.3121 13.5421 16.1348C16.93 14.1788 18.0908 9.84667 16.1348 6.45876L15.9265 6.09792M3.86571 13.5422C1.9097 10.1543 3.07048 5.82219 6.45839 3.86618C8.49754 2.68888 10.8788 2.64071 12.878 3.52768M2.07812 13.6119L4.35483 14.2219L4.96488 11.9452M15.0358 8.05528L15.6459 5.77857L17.9226 6.38861"
                        stroke="#667085"
                        strokeWidth="1.6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                Clear filters
            </button>
        </div>
    )
}
