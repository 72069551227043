import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { toFixedTrunc, getCryptoStatus, useJunoCommonFunctions, getStatus } from '../../../helpers'
import format from 'date-fns/format'
import Pagination from '../../components/pagination/Pagination'
import JncLoading from '../../components/pagination/JncLoading'
import JncEmptyData from '../../components/table/JncEmptyData'
import { useSelector } from 'react-redux'
import Icon from '../../icons/Icon'

const PageSize = 5

export default function JncCryptoTransactionMobile({
    filteredData,
    isLoading,
    noDataText,
    showTablePagination,
    openTransactionDialog,
    totalRows,
    handlePageChange,
}) {
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationData, setPaginationData] = useState([])
    const { getCryptoIcons } = useJunoCommonFunctions()

    useEffect(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [currentPage])

    useEffect(() => {
        const firstPageIndex = 0
        const lastPageIndex = firstPageIndex + PageSize
        const res = filteredData.slice(firstPageIndex, lastPageIndex)
        setPaginationData(res)
    }, [filteredData])


    function renderStatus(x) {
        const statusFunction = x.currencyType === 'fiat' ? getStatus : getCryptoStatus;

        return (
            <div className="d-flex">
                <div
                    className={`jncTransactionsList__status ${statusFunction(x.status.code)
                        .toLowerCase()
                        .replace('/', '')}`}
                >
                    {statusFunction(x.status.code)}
                </div>
            </div>
        );
    }
    const handlePaginationChange = (page) => {
        if (handlePageChange) {
            handlePageChange(page)
        }
        setCurrentPage(page)
    }

    return (
        <div>
            {isLoading ? (
                <JncLoading />
            ) : (
                <div>
                    {paginationData.length < 1 && (
                        <JncEmptyData text={noDataText} />
                    )}
                    <div className="jncTransactionsList">
                        {paginationData.map((x) => {
                            return (
                                <button
                                    onClick={() => openTransactionDialog(x)}
                                    type="button"
                                    key={x.createdDate}
                                    className="jncTransactionsList__item"
                                >
                                    <div className="jncTransactionsList__type">
                                        <div className="jncTransactionsList__type-icon">
                                            {x.currencyType === 'fiat'
                                                ? <Icon id={_.camelCase(x.type)} />
                                                : getCryptoIcons(x.type)
                                            }
                                        </div>
                                        <div className="">
                                            <p className="jncTransactionsList__text">
                                                {x.type}
                                            </p>
                                            <span className="jncTransactionsList__date">
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString()
                                                    ),
                                                    'dd.MM.y'
                                                )}
                                                &nbsp;
                                                {format(
                                                    new Date(
                                                        x.createdDate.toString()
                                                    ),
                                                    'HH:mm'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="">
                                        {x.type == 'Exchange' && (
                                            <p className="jncTransactionsList__text t-right">
                                                {toFixedTrunc(
                                                    x.transactionDetails[0]
                                                        .fromCryptoAmount,
                                                    6
                                                )}{' '}
                                                {
                                                    x.transactionDetails[0]
                                                        .fromCryptoId
                                                }
                                                &ensp;-&ensp;
                                                {toFixedTrunc(
                                                    x.transactionDetails[0]
                                                        .toCryptoAmount,
                                                    6
                                                )}{' '}
                                                {
                                                    x.transactionDetails[0]
                                                        .toCryptoCurrency
                                                }
                                            </p>
                                        )}
                                        {x.type != 'Exchange' && (
                                            <p className="jncTransactionsList__text t-right">
                                                {toFixedTrunc(
                                                    x
                                                        .transactionDetails[0]
                                                        .cryptoAmount || x
                                                            .transactionDetails[0]
                                                        .amount,
                                                    6
                                                )}
                                                {x.transactionDetails[0]
                                                    .currency || x.currency}
                                            </p>
                                        )}
                                        {renderStatus(x)}
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                    {showTablePagination && (
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalRows || filteredData.length}
                            pageSize={PageSize}
                            onPageChange={handlePaginationChange}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
