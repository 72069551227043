import React from 'react'
import { useSelector } from 'react-redux'

export default function JncCurrencyDisplay({ currency }) {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)

    return (
        <div className="jncModal__field disabled-field mb">
            <p className="jncModal__label">From Currency</p>
            <div className="theme-modal-field-input">
                <img
                    data-e2e={currency.currencyShortName}
                    width="30"
                    height="30"
                    src={`${imageBaseUrl}${currency.currencyIcon}`}
                />
                {currency.currencyShortName}
            </div>
        </div>
    )
}
