import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { apiService } from '../../../../common/apiCallService'
import { connect, useSelector, useDispatch } from 'react-redux'
import * as actions from '../../../../Redux/actions'
import FAComp from '../2fa/2FAComp'
import { Switch, styled } from '@mui/material'
import JncSectionTitle from '../global/JncSectionTitle'
import JncAccountConfirmation from '../../templates/JncAccountConfirmation'
import { setToken, setClientData } from '../../../../Redux/actions'
import { clearStorageData } from '../../../../common/getIP'
import { setSuccessMessage } from '../../../../Redux/actions/junoAction'
import InputCopyButton from '../global/InputCopyButton'
import { useNavigate } from 'react-router-dom'
import { getShortName, useJunoCommonFunctions } from '../../../helpers'
import JncProfileDetailsModal from './JncProfileDetailsModal'
import JncPageTitle from '../global/JncPageTitle'

function JncProfile(props) {
    const { useWindowWidth } = useJunoCommonFunctions()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const api_url = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno.clientData)
    const { name, accountNumber } = clientData ? clientData : ''
    const { setActiveTab, activeTab } = props
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false

    const [isChangePasswordSection, setChangePasswordSection] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [isSuccess, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [accountData, setAccountData] = useState({})
    const [receiveTransactionEmails, setReceiveTransactionEmails] =
        useState(true)
    const windowWidth = useWindowWidth()
    const redirectURL = sessionStorage.getItem('redirectURL')
    const [profileDetailsModal, setProfileDetailsModal] = useState(false)

    useEffect(() => {
        if (clientData) {
            setAccountData((prev) => {
                return {
                    name: clientData.name,
                    address: clientData.address,
                    dob: clientData.dob || null,
                    phoneNumber: clientData.phoneNumber || '',
                    city: clientData.city || '',
                    country: clientData.country || '',
                    postCode: clientData.postCode || '',
                    accountNumber: clientData.accountNumber || '',
                }
            })
            setReceiveTransactionEmails(clientData.receiveTransactionEmails)
        }
    }, [clientData])

    const [accountPassword, setAccountPassword] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })

    const handleChangePasswordSection = (bool) => {
        setChangePasswordSection(bool)
        setAlertMsg('')
        if (!bool) {
            setAccountPassword({
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            })
        } else {
            setAccountData(clientData)
        }
        // if (windowWidth > 767) {
        //     setActiveTab(bool ? 'Change password' : 'Personal Information')
        // }
    }

    const onChangeDob = (value) => {
        setAccountData((prev) => {
            return {
                ...prev,
                dob: value,
            }
        })
    }

    const handleInputPasswordChange = (e) => {
        const target = e.target
        if (alertMsg) setAlertMsg('')
        setAccountPassword((prev) => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        })
    }

    const newtoggleEditState = () => {
        setProfileDetailsModal(true)
    }

    const handleInputChange = (event) => {
        const target = event.target
        setAccountData((prev) => {
            return {
                ...prev,
                [target.name]: target.value,
            }
        })
    }

    function checkAccountTextValue(value) {
        if (!value) {
            return <p>N/A</p>
        } else {
            return (
                <React.Fragment>
                    <p>{value}</p>
                    <InputCopyButton copyValue={value} />
                </React.Fragment>
            )
        }
    }

    const updateUserDetails = (transactionEmails, editUser = true) => {
        if (isChangePasswordSection) {
            const passValues = Object.values(accountPassword)
            const passValuesRes = passValues.some((x) => !x)
            if (passValuesRes) {
                setSuccess(false)
                setAlertMsg('Please, fill in all fields')
                return
            }

            if (
                accountPassword.newPassword !== accountPassword.confirmPassword
            ) {
                setSuccess(false)
                setAlertMsg('Passwords do not match')
                return
            }

            if (alertMsg) {
                setSuccess(false)
                return
            }

            if (!alertMsg) {
                apiService(
                    (window.location.hostname === 'localhost' ? api_url : '') +
                        '/restapi/changePassword-client',
                    {
                        clientEmail: clientData.clientEmail,
                        oldPassword: accountPassword.oldPassword,
                        newPassword: accountPassword.newPassword,
                    },
                    (data) => {
                        setSuccess(true)
                        setAlertMsg(data.msg)
                        setAccountPassword({
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                        })
                        dispatch(
                            setSuccessMessage(
                                'Account password updated succesfully'
                            )
                        )
                        localStorage.clear()
                        window.location.href = '/'
                    },
                    (err) => {
                        setSuccess(false)
                        setAlertMsg(err)
                    }
                )
            }
        } else {
            setIsLoading(true)
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                    '/restapi/update-client-user',
                {
                    clientId: clientData.clientId,
                    clientEmail: clientData.clientEmail,
                    name: accountData.name ? accountData.name : clientData.name,
                    address: accountData.address,
                    city: accountData.city,
                    country: accountData.country,
                    dob: accountData.dob,
                    phoneNumber: accountData.phoneNumber,
                    postCode: accountData.postCode,
                    receiveTransactionEmails: editUser
                        ? receiveTransactionEmails
                        : transactionEmails,
                },
                (data) => {
                    setProfileDetailsModal(false)
                    dispatch(actions.setClientData(null))
                    setIsLoading(false)
                    setAlertMsg('')
                    dispatch(
                        setSuccessMessage('Account details updated succesfully')
                    )
                    setSuccess(true)
                },
                (err) => {
                    setIsLoading(false)
                    setSuccess(false)
                    setAlertMsg(err)
                }
            )
        }
    }

    const handleGetEmail = () => {
        setReceiveTransactionEmails((prev) => !prev)
        updateUserDetails(!receiveTransactionEmails, false)
    }

    function onChangeMobileTab(name) {
        setActiveTab(name)
    }

    function logout() {
        dispatch(setToken(null))
        dispatch(setClientData(null))
        clearStorageData()
        if (redirectURL) {
            window.open(redirectURL, '_self')
        } else {
            navigate('/')
            window.location.reload()
        }
    }

    const handleClose = () => {
        setProfileDetailsModal(false)
        setAccountPassword({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
        setAccountData(clientData)
        setChangePasswordSection(false)
        setAlertMsg('')
        setSuccess(false)
    }

    return (
        <>
            <div className="jncProfile__block">
                {activeTab !== 'Personal Information' &&
                    activeTab !== 'Statements' &&
                    activeTab !== 'Additional Users' && (
                        <div className="max-767">
                            <div className="!text-center">
                                <div className="p-[14px] rounded-full text-white flex items-center justify-center text-[32px] font-medium bg-[#18181B] w-20 h-20 m-auto">
                                    {getShortName(name)}
                                </div>
                                <div className="!mt-[14px]">
                                    <p className="jncProfile__user-name">
                                        {name}
                                    </p>
                                    <p className="jncProfile__user-number">
                                        Account number: {accountNumber}
                                    </p>
                                </div>
                            </div>
                            <div className="jncProfile__actions !mt-6">
                                <button
                                    className="jncProfile__actions-btn jncProfileOpenSansFont"
                                    onClick={() =>
                                        onChangeMobileTab(
                                            'Personal Information'
                                        )
                                    }
                                    data-e2e="personal-information"
                                >
                                    Personal Information
                                </button>
                                <button
                                    className="jncProfile__actions-btn"
                                    onClick={() =>
                                        onChangeMobileTab('Statements')
                                    }
                                    data-e2e="statements"
                                >
                                    Statements
                                </button>
                                <button
                                    className="jncProfile__actions-btn"
                                    onClick={() =>
                                        onChangeMobileTab('Additional Users')
                                    }
                                    data-e2e="additional-users"
                                >
                                    Additional Users
                                </button>
                                <div className="jncProfile__actions-btn switch-grid">
                                    Two-Factor Authorisation (2FA)
                                    <FAComp />
                                </div>
                                <div className="jncProfile__actions-btn switch-grid">
                                    Receive transaction emails
                                    <GetEmail
                                        onChange={handleGetEmail}
                                        checked={receiveTransactionEmails}
                                    />
                                </div>
                                <JncAccountConfirmation />
                                <button
                                    onClick={logout}
                                    className="jncProfile__actions-btn logout-btn"
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    )}

                <div className="min-768 jncProfile__header jncProfileOpenSansFont">
                    <JncSectionTitle title="Personal Information">
                        <button
                            data-e2e={'Edit personal information'}
                            onClick={newtoggleEditState}
                            disabled={readOnly}
                            className={`${readOnly && 'jnc-btn-disable'}`}
                        >
                            Edit personal information
                        </button>
                    </JncSectionTitle>
                </div>

                {clientData &&
                    (((activeTab === '' || activeTab === 'Statements') &&
                        windowWidth > 767) ||
                        activeTab === 'Personal Information') && (
                        <div className={`jncProfile__fields`}>
                            {activeTab && windowWidth < 767 && (
                                <JncPageTitle
                                    headerBtn="Edit"
                                    headerBtnAction={newtoggleEditState}
                                    activeTab={activeTab}
                                    resetTab={() => setActiveTab('')}
                                />
                            )}
                            {!isChangePasswordSection &&
                                alertMsg &&
                                !isSuccess && (
                                    <div
                                        className={`jnA-account-password-msg ${
                                            alertMsg ? 'active' : ''
                                        } ${
                                            isSuccess ? 'isSuccess' : 'isAlert'
                                        }`}
                                        role="alert"
                                    >
                                        {alertMsg}
                                    </div>
                                )}
                            <div className="jncProfile__field">
                                <p className="jncModal__label">
                                    Account holder name
                                </p>
                                <div className="jncInputView">
                                    {checkAccountTextValue(clientData.name)}
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">Address</p>

                                <div className="jncInputView">
                                    {checkAccountTextValue(clientData.address)}
                                </div>
                            </div>
                            <div
                                data-e2e="creationDate"
                                className="jncProfile__field"
                            >
                                <p className="jncModal__label">Creation date</p>
                                <div className="jncInputView">
                                    <p>
                                        {format(
                                            new Date(
                                                clientData.createdAt.toString()
                                            ),
                                            'dd/MM/y'
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="jncProfile__field jncProfile__field-dob">
                                <p className="jncModal__label">Birthday</p>

                                <div className="jncInputView">
                                    <p>
                                        {accountData.dob
                                            ? format(
                                                  new Date(
                                                      accountData.dob.toString()
                                                  ),
                                                  'dd/MM/y'
                                              )
                                            : 'N/A'}
                                    </p>
                                    {accountData.dob && (
                                        <InputCopyButton
                                            copyValue={
                                                accountData.dob
                                                    ? format(
                                                          new Date(
                                                              accountData.dob.toString()
                                                          ),
                                                          'dd/MM/y'
                                                      )
                                                    : ''
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">City</p>

                                <div className="jncInputView">
                                    {checkAccountTextValue(accountData.city)}
                                </div>
                            </div>
                            <div
                                data-e2e="accountNumber"
                                className="jncProfile__field"
                            >
                                <p className="jncModal__label">
                                    Account Number
                                </p>
                                <div className="jncInputView">
                                    <p>{clientData.accountNumber}</p>
                                    <InputCopyButton
                                        copyValue={clientData.accountNumber}
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">
                                    Phone Number
                                </p>
                                <div className="jncInputView">
                                    {checkAccountTextValue(
                                        accountData.phoneNumber
                                    )}
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">Country</p>

                                <div className="jncInputView">
                                    {checkAccountTextValue(accountData.country)}
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">Email</p>

                                <div className="jncInputView">
                                    <p className="is-ellipsis">
                                        {clientData.clientEmail}
                                    </p>
                                    <InputCopyButton
                                        copyValue={clientData.clientEmail}
                                    />
                                </div>
                            </div>
                            <div className="jncProfile__field">
                                <p className="jncModal__label">Postcode</p>
                                <div className="jncInputView">
                                    {checkAccountTextValue(
                                        accountData.postCode
                                    )}
                                </div>
                            </div>

                            <div className="jncSectionTitle jncProfile__subheader min-768 jncProfileOpenSansFont">
                                <p>Security settings</p>
                            </div>
                            <div
                                className="jncProfile__subfield min-768 jncProfileOpenSansFont"
                                data-e2e="2FA"
                            >
                                <p className="jncModal__label">
                                    Two factor log in authentication (2FA) -
                                    High Security
                                </p>
                                <div className="" data-e2e="2fa">
                                    <FAComp />
                                </div>
                            </div>

                            <div className="jncSectionTitle jncProfile__subheader min-768 jncProfileOpenSansFont">
                                <p>Email notifications</p>
                            </div>
                            <div
                                className="jncProfile__subfield min-768 jncProfileOpenSansFont"
                                data-e2e="receive-transctions-emails"
                            >
                                <p className="jncModal__label">
                                    Recieve transactional email confirmations
                                </p>
                                <div className="" data-e2e="email-recieve">
                                    <GetEmail
                                        sx={{ m: 1 }}
                                        onChange={handleGetEmail}
                                        checked={receiveTransactionEmails}
                                    />
                                </div>
                            </div>
                            <button
                                onClick={logout}
                                className="min-768 max-991 jncProfile__actions-btn logout-btn"
                                data-e2e="log-out"
                            >
                                Logout
                            </button>
                        </div>
                    )}

                <JncProfileDetailsModal
                    open={profileDetailsModal}
                    onClose={handleClose}
                    handleChangePasswordSection={handleChangePasswordSection}
                    isChangePasswordSection={isChangePasswordSection}
                    alertMsg={alertMsg}
                    accountData={accountData}
                    accountPassword={accountPassword}
                    handleInputPasswordChange={handleInputPasswordChange}
                    handleInputChange={handleInputChange}
                    onChangeDob={onChangeDob}
                    updateUserDetails={updateUserDetails}
                    isSuccess={isSuccess}
                    setAccountPassword={setAccountPassword}
                    isLoading={isLoading}
                />
            </div>
        </>
    )
}

const GetEmail = styled((props) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#2ECA45' : '#12B76A',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#000000' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))

const mapStateToProps = (state) => {
    return {
        clientData: state.juno.clientData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setClientData: (data) => dispatch(actions.setClientData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JncProfile)
