import React, { useEffect, useState } from 'react'
import { getStatus, useJunoCommonFunctions } from '../../../helpers'
import InputCopyButton from '../../components/global/InputCopyButton'
import { toFixedTrunc, getCurrencySymbol } from '../../../helpers'
import { ErrorTransactions } from '../../constant'
import Icon from '../../icons/Icon'
import { useSelector } from 'react-redux'
import BalanceFiatFx from '../balances/actions/fiat/BalanceFiatFx'

export default function TransactionMobileDialogFx({ data }) {
    const details = data && data.transactionDetails[0]
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly = clientData?.readOnly
    const [repeatData, setRepeatData] = useState('')
    const [repeatModal, setRepeatModal] = useState('')
    const [isTransactionRepeatAllowed, setIsTransactionRepeatAllowed] = useState(false)
    const { isRepeatAllowed } = useJunoCommonFunctions()

    useEffect(() => {
        const checkRepeatAllowed = async () => {
            const isAllowed = await isRepeatAllowed(data);
            setIsTransactionRepeatAllowed(isAllowed);
        };

        checkRepeatAllowed();
    }, [data]);
    function openRepeatModal(row) {
        setRepeatData(row)
        setRepeatModal(row.type)
    }
    return (
        <div className="">
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">Status:</p>
                <div className="d-flex">
                    <div
                        className={`jncTransactionsList__status ${getStatus(
                            data.status.code
                        )
                            .toLowerCase()
                            .replace('/', '')}`}
                    >
                        {getStatus(data.status.code)}
                    </div>
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Id:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {data.transactionId || '-'}
                    </p>
                    <InputCopyButton copyValue={data.transactionId} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Currency exchanged from:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.fromCurrency}
                    </p>
                    <InputCopyButton copyValue={details.fromCurrency} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Currency exchanged to:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {details.toCurrency}
                    </p>
                    <InputCopyButton copyValue={details.toCurrency} />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">FX rates:</p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncExpanded-fxRate jncTransactionModal__group-text">
                        {toFixedTrunc(details.fxrate, 6)}
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17 4V20M17 20L13 16M17 20L21 16M7 20V4M7 4L3 8M7 4L11 8"
                                stroke="#667085"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </p>
                    <InputCopyButton
                        copyValue={toFixedTrunc(details.fxrate, 6)}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Amount (exchanged from currency):
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(details.fromCurrency)}{' '}
                        {toFixedTrunc(details.fromAmount, 2)}
                    </p>
                    <InputCopyButton
                        copyValue={`${toFixedTrunc(details.fromAmount, 2)}`}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Amount (exchanged to currency):
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(details.toCurrency)}{' '}
                        {toFixedTrunc(details.toAmount, 2)}
                    </p>
                    <InputCopyButton
                        copyValue={`${toFixedTrunc(details.toAmount, 2)}`}
                    />
                </div>
            </div>
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Balance after transaction:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {getCurrencySymbol(details.fromCurrency)}{' '}
                        {toFixedTrunc(data.balanceAfterTransaction, 2)}
                    </p>
                    <InputCopyButton copyValue={data.balanceAfterTransaction} />
                </div>
            </div>
            {ErrorTransactions.includes(data.status.code) && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        {getStatus(data.status.code)}:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.status.message}
                        </p>
                        <InputCopyButton copyValue={data.status.message} />
                    </div>
                </div>
            )}
            {isTransactionRepeatAllowed && <div className='fixed bottom-0 left-0 p-6 h-[100px] z-50 bg-white flex justify-evenly w-full'>
                <div className=''>
                    <button
                        type='button'
                        disabled={readOnly}
                        onClick={() => openRepeatModal(data)}
                        className={`jncRepeatBtn ${
                            readOnly && 'jnc-btn-disable'
                        }`}
                    >
                        <Icon id='repeatAction' />
                    </button>
                    <span className='mt-2'>Repeat</span>
                </div>
            </div>}
            <BalanceFiatFx
                open={repeatModal === 'FX'}
                onClose={() => setRepeatModal('')}
                row={repeatData}
                selectCurrency={true}
            />
        </div>
    )
}
