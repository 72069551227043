import React from 'react'
import Icon from '../../icons/Icon'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import BalanceCryptoBuy from '../../tables/balances/actions/crypto/BalanceCryptoBuy'
import BalanceCryptoSell from '../../tables/balances/actions/crypto/BalanceCryptoSell'
import { BalanceCryptoReceive } from '../../tables/balances/actions/crypto/BalanceCryptoReceive'
import { BalanceCrytoExchange } from '../../tables/balances/actions/crypto/BalanceCrytoExchange'
import { BalanceCryptoSend } from '../../tables/balances/actions/crypto/BalanceCryptoSend'

const DashboardCryptoActions = () => {
    const [modalAction, setModalAction] = useState('')
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly = clientData?.readOnly

    return (
        <section className="jncSection mb jncActions lg:hidden">
            <div className="jncActions__overflow">
                <div className="jncActions__grid">
                    <button
                        disabled={readOnly}
                        onClick={() => {
                            setModalAction('buy')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="buyIcon" />
                            </div>
                            <p className="jncActions__title">Buy</p>
                        </div>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => {
                            setModalAction('Sell')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="sellIcon" />
                            </div>
                            <p className="jncActions__title">Sell</p>
                        </div>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => {
                            setModalAction('Receive')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="deposit" />
                            </div>
                            <p className="jncActions__title">Receive</p>
                        </div>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => {
                            setModalAction('Exchange')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="fx" />
                            </div>
                            <p className="jncActions__title">Exchange</p>
                        </div>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => {
                            setModalAction('Send')
                        }}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon">
                                <Icon id="transfer" />
                            </div>
                            <p className="jncActions__title">Send</p>
                        </div>
                    </button>
                </div>
            </div>
            <BalanceCryptoBuy
                open={modalAction === 'buy'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoSell
                open={modalAction === 'Sell'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoReceive
                open={modalAction === 'Receive'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCrytoExchange
                open={modalAction === 'Exchange'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceCryptoSend
                open={modalAction === 'Send'}
                onClose={() => setModalAction('')}
                row={{}}
            />
        </section>
    )
}

export default DashboardCryptoActions
