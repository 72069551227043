import React, { useEffect, useState } from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'
import { useSelector } from 'react-redux'
import { JncCustomSelect } from '../../../../components/global/JncCustomSelect'
import Icon from '../../../../icons/Icon'
import { apiService } from '../../../../../../common/apiCallService'
import { getCurrencySymbol, handleCryptoAmountValidation, removeCommas, toFixedTrunc, useJunoCommonFunctions, validateAmount } from '../../../../../helpers'
import ExchangeConfirmation from './confirmModal/ExchangeConfirmation'
import { JncSuccessModal } from '../../../../components/modals/JncSuccessModal'
import { JncFailedModal } from '../../../../components/modals/JncFailedModal'
import { JncPendingModal } from '../../../../components/modals/JncPendingModal'

export const BalanceCrytoExchange = (props) => {
    const { onClose, open } = props
    const { balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const { getCryptoBalances, handleCryptoExchangeRate, getCryptoFee, getTransactions } = useJunoCommonFunctions()
    const apiUrl = useSelector((state) => state.config.api_url)
    const clientData = useSelector((state) => state.juno?.clientData)
    const [amountError, setAmountError] = useState('')
    const [exchangeRate, setExchangeRate] = useState(1)
    const [isConfirmModal, setIsConfirmModal] = useState(false)
    const [exchangeLoading, setExchangeLoading] = useState(false)
    const [quotationData, setQuotationData] = useState()
    const [transactionFee, setTransactionFee] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [fee, setFee] = useState(0)
    const [successModal, setSuccessModal] = useState(false)
    const [processingModal, setProcessingModal] = useState({ open: false, msg: "" })
    const [failedModal, setFailedModal] = useState({ open: false, msg: "" })

    const [formData, setFormData] = useState({
        fromCryptoAmount: null,
        toCryptoAmount: null,
        fromCryptoId: 'BTC',
        toCryptoId: 'ETH',
    })

    const handleClose = (resetForm = true) => {
        onClose()
        setIsLoading(false)
        setIsConfirmModal(false)
        if (resetForm) {
            setFormData({
                fromCryptoAmount: null,
                toCryptoAmount: null,
                fromCryptoId: 'BTC',
                toCryptoId: 'ETH',
            })
        }
        setAmountError('')
    }

    const handleAmountChange = (e) => {
        if (exchangeRate) {
            let fromCryptoAmount, toCryptoAmount, amountError
            const name = e.target.name
            const value = e.target.value

            if (name === 'fromCryptoAmount') {
                toCryptoAmount = exchangeRate && removeCommas(value) * exchangeRate
                toCryptoAmount = toFixedTrunc(toCryptoAmount, 6)
                fromCryptoAmount = value
                const fromCryptoValidationError = handleCryptoAmountValidation(removeCommas(fromCryptoAmount), formData.fromCryptoId, balancesCrypto);
                const validationAmountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoId,
                    balancesCrypto
                )
                amountError = fromCryptoValidationError || validationAmountError
            } else if (name === 'toCryptoAmount') {
                const fixedRate = 1 / exchangeRate
                fromCryptoAmount = fixedRate && removeCommas(value) * fixedRate
                fromCryptoAmount = toFixedTrunc(fromCryptoAmount, 6)
                toCryptoAmount = value
                amountError = validateAmount(
                    removeCommas(fromCryptoAmount),
                    0,
                    formData.fromCryptoId,
                    balancesCrypto
                )
            }
            setFormData({ ...formData, fromCryptoAmount, toCryptoAmount })
            setAmountError(amountError)
        }
    }

    const createCryptoTransaction = () => {
        setIsLoading(true)
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.toCryptoId)?.blockchain
        const payload = {
            currencyType: 'crypto',
            type: 'Exchange',
            clientId: clientData?.clientId,
            transactionEmail: localStorage.getItem('user_name'),
            transactionFee: transactionFee && parseFloat(removeCommas(transactionFee)).toFixed(6),
            transactionDetails: {
                quotationId: quotationData?.rfq_id,
                fromCryptoAmount: parseFloat(removeCommas(formData.fromCryptoAmount)),
                toCryptoAmount: parseFloat(removeCommas(formData.toCryptoAmount)),
                fromCryptoId: formData.fromCryptoId,
                toCryptoId: formData.toCryptoId,
                blockchain,
                exchangeRate,
                quotationPrice: quotationData?.price,
                fee: fee * 100,
                totalAmount: parseFloat(removeCommas(totalAmount)).toFixed(6)
            },
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/create-client-transaction-crypto',
            payload,
            async (data) => {
                getCryptoBalances()
                handleClose(false)
                setSuccessModal(true)
                getTransactions()
            },
            (err) => {
                console.log(err)
                if (!err.includes("processing")) {
                    setFailedModal({ open: true, msg: err })
                } else {
                    setProcessingModal({ open: true, msg: err })
                }
                setIsLoading(false)
            },
        )
    }

    const handleConfirmationModal = () => {
        if (formData.fromCryptoAmount && formData.toCryptoAmount && !amountError) {
            setIsConfirmModal(true)
            handleQuotationAmount()
        }
    }

    useEffect(() => {
        let intervalId = null;
        if (isConfirmModal) {
            intervalId = setInterval(() => {
                handleQuotationAmount();
            }, 10000);
        } else {
            clearInterval(intervalId);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConfirmModal]);

    const handleExchangeRate = async () => {
        setExchangeLoading(true)
        const payload = {
            cryptoList: [formData.toCryptoId],
            cryptoAmount: 1,
            cryptoCurrency: formData.fromCryptoId,
            exchangeConversionType: 'crypto-to-crypto',
        };
        const response = await handleCryptoExchangeRate(payload);
        setExchangeRate(response.cryptoAmounts[formData.toCryptoId]);
        setExchangeLoading(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!formData.fromCryptoId || !open) return;

            if (formData.fromCryptoId) {
                await handleExchangeRate();
            }
            const payload = {
                crypto: formData.fromCryptoId,
                profile: clientData.feeProfile,
                transactionType: 'Exchange',
                clientId: clientData.clientId
            };
            try {
                const cryptoFee = await getCryptoFee(payload);
                setFee(!cryptoFee?.error ? cryptoFee?.data?.fee / 100 : 0);
            } catch (error) {
                console.error('Failed to fetch crypto fee:', error);
            }
        };

        fetchData();
    }, [formData.fromCryptoId, formData.toCryptoId, open]);

    useEffect(() => {
        const { fromCryptoAmount } = formData;

        if (formData) {
            const parsedFromCryptoAmount = fromCryptoAmount && parseFloat(removeCommas(fromCryptoAmount));
            const transactionFee = parsedFromCryptoAmount * parseFloat(fee);
            const totalAmount = parsedFromCryptoAmount + transactionFee;
            setTransactionFee(toFixedTrunc(transactionFee, 2));
            setTotalAmount(toFixedTrunc(totalAmount, 6));
        }
    }, [formData, fee]);

    const handleQuotationAmount = () => {
        const blockchain = balancesCrypto.find(crypto => crypto.currencyShortName === formData.fromCryptoId)?.blockchain
        const payload = {
            userId: clientData?.accountNumber,
            crypto: formData.fromCryptoId,
            blockchain: blockchain,
            cryptoAmount: formData.fromCryptoAmount,
            side: 'Buy',
            fiat: formData.toCryptoId
        }
        apiService(
            (window.location.hostname === 'localhost' ? apiUrl : '') +
            '/restapi/request-for-quotation',
            payload,
            async (res) => {
                if (!res.error) {
                    setQuotationData(res?.data)
                    setFormData({
                        ...formData,
                        toCryptoAmount: toFixedTrunc(formData.fromCryptoAmount * parseFloat(res?.data?.price), 6),
                    })
                } else {
                    setQuotationData(res)
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const handleCryptoId = (field, value) => {
        const oppositeField = field === 'fromCryptoId' ? 'toCryptoId' : 'fromCryptoId';

        if (formData[oppositeField] === value) {
            setAmountError("From and to cryptoId cannot be the same");
        } else {
            setAmountError("");
        }

        setFormData({
            ...formData,
            [field]: value,
            fromCryptoAmount: '',
            toCryptoAmount: '',
        });
    };


    const dialogContentComponent = () => {
        return (
            <>
                {!isConfirmModal ?
                    <>
                        <div className="mb-[20px] flex justify-between items-center max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                From:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    type="text"
                                    name='fromCryptoAmount'
                                    placeholder='Enter From Amount'
                                    data-e2e="from-field"
                                    onChange={handleAmountChange}
                                    value={formData.fromCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.fromCryptoId}
                                    onChange={(e) => handleCryptoId("fromCryptoId", e)}
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-full mb-[20px]">
                            <hr className="flex-grow h-[1px]" />
                            <span className="w-10 h-10 flex items-center justify-center border rounded-full">
                                <Icon id="exchangeIcon" />
                            </span>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center w-full max-sm:flex-wrap">
                            <label className="text-base font-normal text-start">
                                To:
                            </label>
                            <div className="max-sm:pt-3 flex items-center max-sm:w-full">
                                <input
                                    name='toCryptoAmount'
                                    type="text"
                                    data-e2e="to-text-field"
                                    placeholder='Enter To Amount'
                                    onChange={handleAmountChange}
                                    value={formData.toCryptoAmount}
                                    className="bg-input rounded-l-3xl rounded-r-none mr-1 max-sm:w-full py-[17px] pl-6"
                                />
                                <JncCustomSelect
                                    value={formData.toCryptoId}
                                    onChange={(e) => handleCryptoId("toCryptoId", e)}
                                    options={balancesCrypto}
                                    className="max-sm:w-[136px] !rounded-l-none"

                                />
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Rate:
                            </label>
                            <div className="text-base font-normal flex items-center gap-2">
                                {exchangeLoading && <div className="animate-spin h-[18px] w-[18px] border-gray border rounded-full !border-t !border-t-brand-black" />} <span>1 {formData.fromCryptoId} = {toFixedTrunc(exchangeRate, 6)} {formData.toCryptoId}</span>
                            </div>
                        </div>
                        <div className="mb-[30px] flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Fee:
                            </label>
                            <span className="text-base font-normal">
                                {fee * 100}%
                            </span>
                        </div>
                        <div className=" flex justify-between items-center">
                            <label className="text-base font-normal text-start">
                                Total:
                            </label>
                            <span>{getCurrencySymbol(formData.fromCryptoId)} {totalAmount || 0} ({formData.fromCryptoId})</span>
                        </div>
                        {amountError && (
                            <div
                                className="jncAlert alert alert-danger"
                                role="alert"
                                data-e2e={amountError}
                                data-e2e-error="alert"
                            >
                                {amountError}
                            </div>
                        )}
                    </> : <ExchangeConfirmation formData={formData} quotationData={quotationData} exchangeRate={exchangeRate} fee={fee} totalAmount={totalAmount} />
                }
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <div className="w-100">
                {
                    !isConfirmModal ?
                        <div className="jncModal__btns justify-end">
                            <JncButton
                                isFullOnMobile={true}
                                disabled={!fee || amountError}
                                text="Continue"
                                dataE2e="continue-btn"
                                onClickCall={handleConfirmationModal}
                            />
                        </div> :
                        <div className="jncModal__btns grid-on-mobile justify-end no-border">
                            <JncButton
                                isFullOnMobile={true}
                                disabled={isLoading}
                                text="Cancel"
                                onClickCall={() => handleClose()}
                            />
                            <JncButton
                                isFullOnMobile={true}
                                text="Exchange Now"
                                loading={isLoading}
                                disabled={quotationData?.error}
                                onClickCall={() => createCryptoTransaction()}
                            />
                        </div>
                }
            </div>
        )
    }

    const handleSuccessModal = () => {
        setSuccessModal(false)
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'BTC',
            toCryptoId: 'ETH',
        })
    }

    const handleFailedModal = () => {
        setFailedModal({ open: false, msg: "" })
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'BTC',
            toCryptoId: 'ETH',
        })
        setIsConfirmModal(false)
    }

    const handleProcessModal = () => {
        setProcessingModal({ open: false, msg: "" })
        setFormData({
            fromCryptoAmount: null,
            toCryptoAmount: null,
            fromCryptoId: 'BTC',
            toCryptoId: 'ETH',
        })
        setIsConfirmModal(false)
        getCryptoBalances()
        getTransactions()
    }

    return (
        <>
            <JunoClientActionModal
                onClose={handleClose}
                titles={{ start: 'Exchange', confirm: 'Exchange Confirmation' }}
                backTo={{ account: () => setIsConfirmModal(false) }}
                states={{
                    start: true,
                    confirm: false,
                }}
                open={open}
                dialogContent={dialogContentComponent()}
                dialogActions={dialogActionsComponent()}
            />
            <JncSuccessModal
                open={successModal}
                onClose={handleSuccessModal}
                amount={formData.toCryptoAmount}
                currency={formData.toCryptoId}
                title="Exchange Confirmed!"
                subtitle="Has been deposited into your Juno X wallet."
            />
            <JncFailedModal
                open={failedModal.open}
                onClose={handleFailedModal}
                error={failedModal.msg}
                title="Exchange Failed!"
            />
            <JncPendingModal
                open={processingModal.open}
                title="Pending..."
                onClose={handleProcessModal}
                error={processingModal.msg}
            />
        </>
    )
}
