import React from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import * as actions from '../../../../Redux/actions'
import Dialog from '@mui/material/Dialog'
import { apiService } from '../../../../common/apiCallService'
import JncAuthClose from './auth/JncAuthClose'
import JncAuthTitle from './auth/JncAuthTitle'
import JncAuthBottom from './auth/JncAuthBottom'

class JunoForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailId: '',
            redirectToLogin: 0,
            alertMsg: null,
            showSuccessAlertModal: false,
            emailError: '',
            headerLogo: sessionStorage.getItem("logo"),
            logoWidth: sessionStorage.getItem("logoWidth"),
        }
    }

    forgotPasswordEmail = async () => {
        if (
            this.state.emailId &&
            this.state.emailId !== '' &&
            validator.isEmail(this.state.emailId)
        ) {
            this.setState({ alertMsg: null })
            this.props.setLoading(true)
            apiService(
                (window.location.hostname === 'localhost'
                    ? this.props.api_url
                    : '') + '/restapi/forgetPassword-client',
                {
                    clientEmail: this.state.emailId,
                    hostname: window.location.hostname,
                },
                (data) => {
                    this.props.setLoading(false)
                    if (data && data.success) {
                        this.setState({
                            showSuccessAlertModal: true,
                        })
                    } else if (data && data.msg) {
                        this.setState({
                            alertMsg: err?.responseJSON?.err,
                            emailError: data.msg,
                        })
                    } else {
                        this.setState({ alertMsg: 'Something Went Wrong!' })
                        // console.log(data)
                    }
                },
                (err) => {
                    this.props.setLoading(false)
                    this.setState({
                        alertMsg: 'Something Went Wrong!',
                        emailError: 'Please enter valid email address!',
                    })
                    console.log(err)
                },
            )
        } else {
            this.setState({ emailError: 'Please enter valid email address!' })
        }
    }
    handleInputChange = (event) => {
        const target = event.target
        this.setState({
            [target.name]: target.value,
            emailError: '',
        })
    }

    render() {
        const { headerLogo, logoWidth } = this.state;
        return (
            <Dialog
                className="jncAuth"
                fullScreen
                open={this.props.show}
                onClose={() => {
                    this.props.closeModal()
                }}
            >
                <div className="jncAuth__body bg-brand-black">
                    <div>
                        <JncAuthClose onClose={() => this.props.closeModal()} />
                        {!this.state.showSuccessAlertModal ? (
                            <div className="jncAuth__container  p-8 !max-w-[424px] bg-white rounded-lg">
                                <div className="jncAuth__logo">
                                    {headerLogo && headerLogo !== 'null' ? (
                                        <img src={headerLogo} alt="logo" width={logoWidth || "80"} height="auto" />
                                    ) : (
                                        <img className='w-36' src={`${this.props.imageBaseURl}/landingpage/dark-logo.svg`} alt="logo" />
                                    )}
                                </div>
                                <JncAuthTitle
                                    title="Forgot Password"
                                    subtitle="Enter your email and we’ll send a reset link."
                                />
                                {this.state.alertMsg && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        {this.state.alertMsg}
                                    </div>
                                )}
                                {this.state.emailError && (
                                    <div
                                        className="alert alert-danger"
                                        role="alert"
                                    >
                                        {this.state.emailError}
                                    </div>
                                )}
                                {/* {this.state.redirectToLogin == 1 && <Redirect to='/login' />} */}
                                <form>
                                    <div className="jncAuth__field">
                                        <p className="jncAuth__field-label">
                                            Email
                                        </p>
                                        <input
                                            type="email"
                                            value={this.state.emailId}
                                            data-e2e="enter-your-email"
                                            placeholder="Enter your email"
                                            onChange={this.handleInputChange}
                                            name="emailId"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        disabled={this.state.emailError !== ''}
                                        className={`jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold full-width mt-6  !bg-brand-black hover:bg-brand-gray ${this.state.emailError === '' ? '' : 'disabled'}`}
                                        onClick={() => {
                                            this.forgotPasswordEmail()
                                        }}
                                    >
                                        <div data-e2e="send-recovery-link-btn">
                                            Send recovery link
                                        </div>
                                    </button>
                                    <div className="jncAuth__underBtn">
                                        Remembered password?&nbsp;
                                        <button
                                            type="button"
                                            className="jncAuth__link"
                                            onClick={this.props.openLoginModal}
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className="jncAuth__container p-8 !max-w-[360px] bg-white rounded-lg">
                                <svg
                                    className="jncAuth__successIcon"
                                    width="60"
                                    height="60"
                                    viewBox="0 0 60 60"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="59"
                                        height="59"
                                        rx="29.5"
                                        fill="#ECFDF3"
                                    />
                                    <circle
                                        cx="30"
                                        cy="30"
                                        r="15"
                                        fill="#62CA7A"
                                    />
                                    <path
                                        d="M22 30.25L23.5 28.75L27.25 32.5L35.5 24.25L37 25.75L27.25 35.5L22 30.25Z"
                                        fill="white"
                                    />
                                    <rect
                                        x="0.5"
                                        y="0.5"
                                        width="59"
                                        height="59"
                                        rx="29.5"
                                        stroke="#D1FADF"
                                    />
                                </svg>
                                <p className="jncAuth__successTitle">
                                    Check your email
                                </p>
                                <p className="jncAuth__subtitle">Please check the email address <span className='font-medium text-[#101828]'>{this.state.emailId}</span>  for instruction to reset your password</p>
                                <button
                                    type="button"
                                    className="jncAuth__Button w-full rounded-full flex justify-center h-11 items-center text-white font-semibold full-width mt-6  !bg-brand-black hover:bg-brand-gray "
                                    onClick={this.props.openLoginModal}
                                >
                                    <p>Continue</p>
                                </button>
                            </div>
                        )}
                    </div>
                    <JncAuthBottom />
                </div>
            </Dialog>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        api_url: state.config.api_url,
        imageBaseURl: state.config.image_base_url,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (data) => dispatch(actions.setLoading(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JunoForgotPassword)
