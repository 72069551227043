import React, { useState } from 'react'
import BalanceFiatDeposit from '../../tables/balances/actions/fiat/BalanceFiatDeposit'
import BalanceFiatFx from '../../tables/balances/actions/fiat/BalanceFiatFx'
import BalanceFiatPaymentOut from '../../tables/balances/actions/fiat/BalanceFiatPaymentOut'
import BalanceFiatTransfer from '../../tables/balances/actions/fiat/BalanceFiatTransfer'
import BalanceFiatWithdraw from '../../tables/balances/actions/fiat/BalanceFiatWithdraw'
import Icon from '../../icons/Icon'
import { useDispatch, useSelector } from 'react-redux'
import DashboardCurrencyDistribution from './DashboardCurrencyDistribution'
import MerchantTransfer from '../../tables/balances/actions/fiat/MerchantTransfer'
import { setErrorMessage } from '../../../../Redux/actions/junoAction'
import { useJunoCommonFunctions } from '../../../helpers'

export default function DashboardActions() {
    const dispatch = useDispatch()
    const [modalAction, setModalAction] = useState('')
    const clientData = useSelector((state) => state.juno.clientData)
    const { getBalances } = useJunoCommonFunctions()
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false

    const handleModalOpen = async (modalType) => {
        const balances = await getBalances();
        let unfreezeBalances = [];

        if (balances) {
            unfreezeBalances = balances.filter(balance => !balance.isFreeze);
        }

        if (
            unfreezeBalances.length === 0
        ) {
            dispatch(setErrorMessage(
                "There are no available currencies at the moment. Please contact your administrator for further assistance."
            ));
            return;
        } else if ((unfreezeBalances.length  <= 1 && modalType === 'fx')) {
            dispatch(setErrorMessage(
                "Currency exchange requires multiple available currencies. Please contact your administrator to add another currency and proceed with the FX transaction."
            ));
            return
        }

        setModalAction(modalType);
    };

    return (
        <section className="jncSection mb jncActions lg:hidden">
            <div className="jncActions__overflow">
                <div className="jncActions__grid">
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('deposit')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div
                                className="jncActions__icon"
                                data-e2e="deposit-box"
                            >
                                <Icon id="deposit" />
                            </div>
                            <p className="jncActions__title">Deposit</p>
                        </div>
                        <p className="jncActions__text">
                            Top up your account in any of the currencies Juno
                            offers.
                        </p>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('withdraw')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div
                                className="jncActions__icon"
                                data-e2e="withdraw-box"
                            >
                                <Icon id="withdrawal" />
                            </div>
                            <p className="jncActions__title">Withdrawal</p>
                        </div>
                        <p className="jncActions__text">
                            Withdraw to any of your other named bank accounts.
                        </p>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('paymentOut')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div
                                className="jncActions__icon"
                                data-e2e="third-party-payment-box"
                            >
                                <Icon id="paymentOut" />
                            </div>
                            <p className="jncActions__title">
                                Third-party payment
                            </p>
                        </div>
                        <p className="jncActions__text">
                            Make third-party payments to business partners,
                            suppliers and employees.{' '}
                        </p>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('transfer')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div
                                className="jncActions__icon"
                                data-e2e="transfer-box"
                            >
                                <Icon id="transfer" />
                            </div>
                            <p className="jncActions__title">Transfer</p>
                        </div>
                        <p className="jncActions__text">
                            Transfer funds instantly to any other Juno account
                        </p>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('fx')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon" data-e2e="fx-box">
                                <Icon id="fx" />
                            </div>
                            <p className="jncActions__title">FX</p>
                        </div>
                        <p className="jncActions__text">
                            FX in real time to and from any of the currencies
                            Juno offers
                        </p>
                    </button>
                    <button
                        disabled={readOnly}
                        onClick={() => handleModalOpen('merchantTransfer')}
                        className={`${
                            readOnly ? 'jnc-btn-disable' : ''
                        } jncActions__btn`}
                    >
                        <div className="jncActions__top">
                            <div className="jncActions__icon" data-e2e="fx-box">
                                <Icon id="transfer" />
                            </div>
                            <p className="jncActions__title">Merchant</p>
                        </div>
                    </button>
                </div>
            </div>
            <div className="mt-4 max-991">
                <DashboardCurrencyDistribution />
            </div>
            <BalanceFiatDeposit
                open={modalAction === 'deposit'}
                onClose={() => setModalAction('')}
                row={{}}
            />
            <BalanceFiatWithdraw
                open={modalAction === 'withdraw'}
                onClose={() => setModalAction('')}
                row={{}}
                selectCurrency={true}
            />
            <BalanceFiatPaymentOut
                open={modalAction === 'paymentOut'}
                onClose={() => setModalAction('')}
                row={{}}
                selectCurrency={true}
            />
            <BalanceFiatFx
                open={modalAction === 'fx'}
                onClose={() => setModalAction('')}
                row={{}}
                selectCurrency={true}
            />
            <BalanceFiatTransfer
                open={modalAction === 'transfer'}
                onClose={() => setModalAction('')}
                row={{}}
                selectCurrency={true}
            />
            <MerchantTransfer
                open={modalAction === 'merchantTransfer'}
                onClose={() => setModalAction('')}
                row={{}}
                selectCurrency={true}
            />
        </section>
    )
}
