import React from 'react';

class Loading extends React.Component {
   render() {
      return (
          <div className="app-content-loading">
              <div className='loading_main row' >
                  <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                  </div>
              </div>
          </div>
      );
   }
}

export default Loading;
