import React from 'react'
import JunoClientActionModal from '../../components/table/JunoClientActionModal'
import Icon from '../../icons/Icon'
import JncTransactionFilters from './JncTransactionFilters'
import JncButton from '../../components/global/JncButton'

export default function TransactionMobileFilters({
    onClose,
    open,
    filter,
    setFilter,
}) {
    function DialogContentComponent() {
        return (
            <div>
                <JncTransactionFilters filter={filter} setFilter={setFilter} />
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="jncTransactions__filters-actions">
                <JncButton
                    text="Save"
                    onClickCall={onClose}
                    isFullOnMobile={true}
                />
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onClose}
            title="Filters"
            open={open}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
            fullScreen={true}
        ></JunoClientActionModal>
    )
}
