import IsFailed from '../form/IsFailed'
import IsDelete from '../form/IsDelete'
import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import JncDialogTitle from '../modals/JncDialogTitle'
import JncDialogTitleAccounts from '../modals/JncDialogTitleAccounts'
import JncDialogTitleCustom from '../modals/JncDialogTitleCustom'
import { DialogActions } from '@mui/material'
import { useJunoCommonFunctions } from '../../../helpers'
import JncDialogTitleTransaction from '../modals/JncDialogTitleTransaction'
import DialogTitle from '@mui/material/DialogTitle'

export default function JunoClientActionModal(props) {
    const {
        customDialogTitle,
        contentClassName,
        dialogClassName,
        dialogTitle,
        onClose,
        failedErr,
        open,
        title,
        children,
        isLoading,
        isFailed,
        failedText,
        reset,
        formData,
        isDelete,
        setIsDelete,
        onSuccessDelete,
        isEditAccount,
        hideIsEditAccount,
        dialogContent,
        dialogActions,
        titles,
        backTo,
        states,
        transaction,
        fullScreen,
        actionClassName
    } = props
    const { useWindowWidth } = useJunoCommonFunctions()
    const windowWidth = useWindowWidth()

    function handleOnClose() {
        if (!isLoading) {
            onClose()
        }
    }

    return (
        <Dialog
            className={`jncModal ${isDelete ? 'is-delete' : ''} 
            ${isFailed || isDelete ? 'margin-x' : ''} 
            ${fullScreen ? 'is-fullscreen' : ''}`}
            classes={{paper:dialogClassName?dialogClassName:''}}
            onClose={handleOnClose}
            open={open}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            fullScreen={
                (windowWidth < 768 && !isFailed && !isDelete) || fullScreen
            }
        >
            {!isDelete && title && (
                <JncDialogTitle title={title} onClose={onClose} />
            )}
            {dialogTitle && !isDelete &&
                <DialogTitle id="scroll-dialog-title" className="jncAccountModal__header">
                    {dialogTitle}
                </DialogTitle>}

            {!isDelete &&
                !isFailed &&
                customDialogTitle === 'accounts' &&
                formData && (
                    <JncDialogTitleAccounts
                        hideIsEditAccount={hideIsEditAccount}
                        isEditAccount={isEditAccount}
                        onClose={onClose}
                        formData={formData}
                    />
                )}

            {!isDelete &&
                !isFailed &&
                customDialogTitle === 'transaction-mobile' && (
                    <JncDialogTitleTransaction
                        transaction={transaction}
                        onClose={onClose}
                    />
                )}


            {!isDelete && !isFailed && titles && (
                <JncDialogTitleCustom
                    titles={titles}
                    backTo={backTo}
                    states={states}
                    onClose={onClose}
                    formData={formData}
                    className={titles.className}
                />
            )}

            <DialogContent dividers={dialogActions}
            className= {contentClassName}
            >
                <div className="jncModal__body">
                    {isFailed && (
                        <IsFailed
                            text={failedText}
                            failedErr={failedErr}
                            reset={reset}
                        />
                    )}
                    {isDelete && (
                        <IsDelete
                            onSuccessDelete={onSuccessDelete}
                            setIsDelete={setIsDelete}
                            accountId={formData._id}
                        />
                    )}
                    {!isFailed && !isDelete && (dialogContent || children)}
                </div>
            </DialogContent>

            {!isFailed && !isDelete && !children && (
                <DialogActions className={actionClassName}>{dialogActions}</DialogActions>
            )}
        </Dialog>
    )
}
