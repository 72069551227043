import React from 'react'
import RoutesList from './client//Routes.js';
import Loading from './client/common/Loading.js';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/css/style.scss'


const App = () => {
  const loading = useSelector((state) => state.common.loading);

  return <div id="ProjectApp" className="projectApp">
    {loading && <Loading />}
    <RoutesList />
  </div>

}

export default App
