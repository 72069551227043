import React from 'react'
import CopyButton from './CopyButton'

export default function AccountAddress({ data }) {
    return (
        <div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">Bank name</p>
                    <p className="jncAccountModal__value">
                        {data.bankName || '-'}
                    </p>
                </div>
                {data.bankName && <CopyButton copyValue={data.bankName} />}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">
                        Beneficiary Address
                    </p>
                    <p className="jncAccountModal__value">
                        {data.beneficiaryAddress || '-'}
                    </p>
                </div>
                {data.beneficiaryAddress && (
                    <CopyButton copyValue={data.beneficiaryAddress} />
                )}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">Bank Address</p>
                    <p className="jncAccountModal__value">
                        {data.bankAddress || '-'}
                    </p>
                </div>
                {data.bankAddress && (
                    <CopyButton copyValue={data.bankAddress} />
                )}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">Beneficiary Name</p>
                    <p className="jncAccountModal__value">
                        {data.beneficiaryName || '-'}
                    </p>
                </div>
                {data.beneficiaryName && (
                    <CopyButton copyValue={data.beneficiaryName} />
                )}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">
                        Beneficiary Account number
                    </p>
                    <p className="jncAccountModal__value">
                        {data.beneficiaryAccountNumber || '-'}
                    </p>
                </div>
                {data.beneficiaryAccountNumber && (
                    <CopyButton copyValue={data.beneficiaryAccountNumber} />
                )}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">IBAN</p>
                    <p className="jncAccountModal__value">{data.iban || '-'}</p>
                </div>
                {data.iban && <CopyButton copyValue={data.iban} />}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">Sort code</p>
                    <p className="jncAccountModal__value">
                        {data.sortCode || '-'}
                    </p>
                </div>
                {data.sortCode && <CopyButton copyValue={'Corporation Bank'} />}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">SWIFT</p>
                    <p className="jncAccountModal__value">
                        {data.swift || '-'}
                    </p>
                </div>
                {data.swift && <CopyButton copyValue={data.swift} />}
            </div>
            <div className="jncAccountModal__group">
                <div>
                    <p className="jncAccountModal__label">Reference</p>
                    <p className="jncAccountModal__value">
                        {data.reference || '-'}
                    </p>
                </div>
                {data.reference && <CopyButton copyValue={data.reference} />}
            </div>
        </div>
    )
}
