import React from 'react'
import JunoClientActionModal from '../../../../components/table/JunoClientActionModal'
import JncButton from '../../../../components/global/JncButton'

export default function BalanceFiatDeposit({ onClose, open }) {
    function DialogContentComponent() {
        return (
            <div>
                <p className="jncModal__text">
                    Please contact your Account Director for details of how to
                    deposit funds into your Juno Money account.
                </p>
            </div>
        )
    }

    function DialogActionsComponent() {
        return (
            <div className="jncModal__btns justify-end">
                <JncButton
                    onClickCall={onClose}
                    isFullOnMobile={true}
                    text="Close"
                />
            </div>
        )
    }

    return (
        <JunoClientActionModal
            onClose={onClose}
            title="Deposit"
            open={open}
            dialogContent={DialogContentComponent()}
            dialogActions={DialogActionsComponent()}
        ></JunoClientActionModal>
    )
}
