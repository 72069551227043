import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'
import JncPaymentConfirmation from '../../templates/JncPaymentConfirmation'

export default function PaymentOutExpanded(data) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee
    const balanceBeforeDeduction = data.transactionFee
        ? Number(details.amount) + Number(data.transactionFee)
        : details.amount

    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                {/* <<<<<< 1st Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Transaction Id</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">
                                {data.transactionId || '-'}
                            </p>
                            <CopyButton copyValue={data.transactionId} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Currency</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.currency || '-'}</p>
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">
                            {!transactionFee
                                ? 'Amount'
                                : 'Amount before deduction'}
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(data.currency)}{' '}
                                {toFixedTrunc(balanceBeforeDeduction, 2) || '-'}
                            </p>
                            <CopyButton
                                copyValue={`$ ${toFixedTrunc(
                                    balanceBeforeDeduction,
                                    2
                                )}`}
                            />
                        </div>
                    </div>
                    {transactionFee ? (
                        <div className="jncExpanded-block">
                            <p className="jncExpanded-label">
                                Amount after deduction
                            </p>
                            <div className="jncExpanded-block-copy jncExpanded-text">
                                <p>
                                    {getCurrencySymbol(data.currency)}{' '}
                                    {toFixedTrunc(details.amount, 2) || '-'}
                                </p>
                                <CopyButton
                                    copyValue={`$ ${toFixedTrunc(
                                        details.amount,
                                        2
                                    )}`}
                                />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className="">
                        <p className="jncExpanded-label">Transaction Fee</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(data.currency)}{' '}
                                {toFixedTrunc(transactionFee, 2) || '-'}
                            </p>
                            <CopyButton copyValue={transactionFee} />
                        </div>
                    </div>
                    <div className="">
                        <p className="jncExpanded-label">
                            Balance after transaction
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {getCurrencySymbol(data.currency)}{' '}
                                {toFixedTrunc(
                                    data.balanceAfterTransaction,
                                    2
                                ) || '-'}
                            </p>
                            <CopyButton
                                copyValue={data.balanceAfterTransaction}
                            />
                        </div>
                    </div>
                </div>

                {/* <<<<<< 2nd Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Beneficiary name</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.beneficiaryName || '-'}</p>
                            <CopyButton copyValue={details.beneficiaryName} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Beneficiary address</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.beneficiaryAddress || '-'}</p>
                            <CopyButton
                                copyValue={details.beneficiaryAddress}
                            />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Bank name</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.bankName || '-'}</p>
                            <CopyButton copyValue={details.bankName} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">
                            Beneficiary account number
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.beneficiaryAccountNumber || '-'}</p>
                            <CopyButton
                                copyValue={details.beneficiaryAccountNumber}
                            />
                        </div>
                    </div>

                    <div className="jncExpanded-block col-span-2">
                        <p className="jncExpanded-label">Reference</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.reference || '-'}</p>
                            <CopyButton copyValue={details.reference} />
                        </div>
                    </div>
                </div>

                {/* <<<<<< 3rd Column >>>>>>>>>>> */}
                <div className="flex flex-col gap-3">
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Bank address</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.bankAddress || '-'}</p>
                            <CopyButton copyValue={details.bankAddress} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">IBAN</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.iban || '-'}</p>
                            <CopyButton copyValue={details.iban} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">SWIFT</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{details.swift || '-'}</p>
                            <CopyButton copyValue={details.swift} />
                        </div>
                    </div>
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">Sort code</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">
                                {details.sortCode || '-'}
                            </p>
                            <CopyButton copyValue={details.sortCode} />
                        </div>
                    </div>
                </div>

                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
            {data.status.code === 'PrivateClient:TransactionCompleted' && (
                <JncPaymentConfirmation data={data} />
            )}
        </div>
    )
}
