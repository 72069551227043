import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { apiService } from '../../../common/apiCallService';
import { useNavigate } from 'react-router-dom';

export default function MaintenancePage() {
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const apiUrl = useSelector((state) => state.config.api_url)
    const navigate = useNavigate()

    const checkMaintenance = async () => {
        try {
            apiService(
                (window.location.hostname === 'localhost' ? apiUrl : '') +
                '/restapi/get-settings',
                {
                },
                (data) => {
                    if (!data[0].value) {
                        navigate("/")
                    }
                },
            )

        } catch (err) {
            console.error("Error fetching maintenance setting:", err);
        }
    };

    useEffect(() => {
        checkMaintenance();
    }, []);

    return (
        <div style={{ margin: "auto" }} className='maintaincePage'>
            <div className='maintainceContainer'>
                <div className='maintainceContent'>
                    <div>
                        <div style={{ marginBottom: "20px" }} className='maintainceImage'>
                            <img src={`${imageBaseUrl}/maintenance/maintenanceIcon.svg`} width={100} alt="maintaince image" />
                        </div>
                        <div className='maintainceText'>
                            Site Under Maintenance
                        </div>
                        <div className='maintainceSubtext'>
                            We’ll be back shortly
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
