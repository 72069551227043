import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import JncSectionTitle from '../global/JncSectionTitle'
import ManageCurrenciesModal from '../modals/ManageCurrenciesModal'
import Icon from '../../icons/Icon'
import { defaultCurrenciesToDisplay, useJunoCommonFunctions } from '../../../helpers'
import JncCryptoCard from '../global/JncCryptoCard'

const JncCryptoBalances = ({ isDashboardPage, isBalancesTab }) => {
    const [activeData, setActiveData] = useState({})
    const [activeModal, setActiveModal] = useState('')
    const [manageModalState, setManageModalState] = useState(false)
    const balancesData = useSelector((state) => state.juno.balancesCrypto)
    const [selectedCurrencies, setSelectedCurrencies] = useState(
        defaultCurrenciesToDisplay
    )
    const { useWindowWidth } = useJunoCommonFunctions()
    const [balancesDataDashboard, setBalancesDataDashboard] = useState([])
    const windowWidth = useWindowWidth()

    const [currencyModal, setCurrencyModal] = useState(false)

    function openBalanceMobile(data) {
        setActiveData(data)
        setCurrencyModal(true)
    }

    function onCloseManageModal() {
        setManageModalState(false)
        const storedSelected = localStorage.getItem('manageCurrencies')
        setSelectedCurrencies(storedSelected ? JSON.parse(storedSelected) : [])
    }

    useEffect(() => {
        if (balancesData) {
            const data = balancesData.filter((x) => {
                return selectedCurrencies.includes(x.currencyShortName)
            })

            setBalancesDataDashboard(data)
        }
    }, [balancesData, selectedCurrencies])

    useEffect(() => {
        const storedSelected = localStorage.getItem('manageCurrencies')

        if (storedSelected && JSON.parse(storedSelected).length !== 0) {
            setSelectedCurrencies(JSON.parse(storedSelected))
        } else {
            localStorage.setItem(
                'manageCurrencies',
                JSON.stringify(selectedCurrencies),
            )
        }
    }, [])

    useEffect(() => {
        if (windowWidth > 767 && currencyModal) {
            setCurrencyModal(false)
        }
    }, [windowWidth])

    return (
        <section
            className={`jncSection jncBalances mb mt ${!isBalancesTab ? 'hidden' : ''} ${isDashboardPage ? 'is-dashboard' : ''}`}
        >
            <div className="jncCardsGrid">
                {isDashboardPage &&
                    windowWidth > 767 &&
                    balancesDataDashboard.map((x) => {
                        return (
                            <JncCryptoCard
                                key={x.currencyShortName}
                                data={x}
                            />
                        )
                    })}
                {!isDashboardPage &&
                    windowWidth > 767 &&
                    balancesData.map((x) => {
                        return (
                            <JncCryptoCard
                                key={x.currencyShortName}
                                data={x}
                            />
                        )
                    })}
                {!isDashboardPage &&
                    windowWidth < 768 &&
                    balancesData.map((x) => {
                        return (
                            <div
                                key={x.currencyShortName}
                                onClick={() => openBalanceMobile(x)}
                            >
                                <JncCryptoCard
                                    data={x}
                                />
                            </div>
                        )
                    })}
                {isDashboardPage &&
                    windowWidth < 768 &&
                    balancesDataDashboard.map((x) => {
                        return (
                            <div key={x.currencyShortName}
                                onClick={() => openBalanceMobile(x)}
                            >
                                <JncCryptoCard
                                    data={x}
                                />
                            </div>
                        )
                    })}
                {isDashboardPage && windowWidth > 767 && (
                    <button
                        onClick={() => setManageModalState(true)}
                        className="jncAddItem no-mt manage-currencies"
                    >
                        <Icon id="manageCurrencies" />
                        <p data-e2e="manage-currencies">Manage currencies</p>
                    </button>
                )}
            </div>
            {isDashboardPage && (
                <ManageCurrenciesModal
                    balancesData={balancesData}
                    open={manageModalState}
                    onClose={onCloseManageModal}
                />
            )}
        </section>
    )
}

export default JncCryptoBalances