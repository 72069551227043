import React, { useEffect, useState } from 'react'
import { getCurrencySymbol, toFixedTrunc, typeItems, useJunoCommonFunctions } from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import BalanceFiatDeposit from "../../tables/balances/actions/fiat/BalanceFiatDeposit";
import BalanceFiatFx from "../../tables/balances/actions/fiat/BalanceFiatFx";
import BalanceFiatPaymentOut from "../../tables/balances/actions/fiat/BalanceFiatPaymentOut";
import BalanceFiatTransfer from "../../tables/balances/actions/fiat/BalanceFiatTransfer";
import BalanceFiatWithdraw from "../../tables/balances/actions/fiat/BalanceFiatWithdraw";
import DashboardCurrencyDistribution from './DashboardCurrencyDistribution';
import MerchantTransfer from '../../tables/balances/actions/fiat/MerchantTransfer';
import { setErrorMessage } from '../../../../Redux/actions/junoAction'

const DashboardHeader = () => {
    const dispatch = useDispatch()
    const unfreezeFiatCurrencies = useSelector((state) => state.juno.unfreezeFiatCurrencies)
    const clientData = useSelector((state) => state.juno.clientData);
    const [modalAction, setModalAction] = useState("");
    const readOnly = clientData && clientData.readOnly ? clientData.readOnly : false
    const totalFiatAmount = useSelector((state) => state.juno.totalFiatAmount);
    const { name } = clientData ? clientData : '';
    const [unfreezeBalances, setUnfreezeBalances] = useState([])
    const [isTransactionsDisabled, setIsTransactionsDisables] = useState(false)
    const { getBalances } = useJunoCommonFunctions()
    const { getClientFeeSetting } = useJunoCommonFunctions()

    useEffect(() => {
        fetchFeeSetting()
    }, [modalAction])

    const fetchFeeSetting = async () => {
        const transactionType = typeItems.find(
            (item) => item.label === modalAction
        )?.value

        const payload = {
            clientId: clientData?.clientId,
            transactionType: transactionType,
            currencyType: 'fiat',
        }

        transactionType && (await getClientFeeSetting(payload))
    }
    useEffect(() => {
        setUnfreezeBalances(unfreezeFiatCurrencies);
    }, [unfreezeFiatCurrencies]);

    useEffect(() => {
        if(unfreezeBalances.length === 0) {
            setIsTransactionsDisables(true)
        } else  {
            setIsTransactionsDisables(false)
        }
    }, [unfreezeBalances])

    const handleModalOpen = async (modalType) => {
        const balances = await getBalances();
        let unfreezeBalances = [];

        if (balances) {
            unfreezeBalances = balances.filter(balance => !balance.isFreeze);
        }

        if (
            unfreezeBalances.length === 0
        ) {
            dispatch(setErrorMessage(
                "There are no available currencies at the moment. Please contact your administrator for further assistance."
            ));
            return;
        } else if ((unfreezeBalances.length  <= 1 && modalType === 'fx')) {
            dispatch(setErrorMessage(
                "Currency exchange requires multiple available currencies. Please contact your administrator to add another currency and proceed with the FX transaction."
            ));
            return
        }

        setModalAction(modalType);
    };

    return (
        <div>
            <div className="jncTotalBalance max-991">
                <p>Total Balance</p>
                <p data-e2e="total-balance" data-e2e-symbol={getCurrencySymbol("USD")} data-e2e-amount={toFixedTrunc(totalFiatAmount, 2)}>{getCurrencySymbol("USD")} {toFixedTrunc(totalFiatAmount, 2)}</p>
            </div>
            <div className=" jncCard min-992 jncDesktopTotalBalance">
                <div className='jncDesktopTotalBalance__container '>
                    <div>
                        <p className='jncDesktopTotalBalance__welcomeMessage' data-e2e-msg="welcome" data-e2e={`Welcome back, ${name}`}>{`Welcome back, ${name}`}</p>
                        <p className='jncDesktopTotalBalance__subheading' >Your total balance is <span className='jncDesktopTotalBalance__balance' data-e2e={toFixedTrunc(totalFiatAmount, 2)} data-e2e-amount="amount">{getCurrencySymbol("USD")} {toFixedTrunc(totalFiatAmount, 2)}</span> </p>
                    </div>
                    <div className='dashboardHeaderActions' >
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("deposit")} >
                            <p data-e2e="deposit-upper-tab">Deposit</p>
                        </button>
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("withdraw")} >
                            <p data-e2e="withdraw-upper-tab">Withdrawal</p>
                        </button>
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("paymentOut")} >
                            <p data-e2e="third-party-payment-upper-tab">Third-party payment</p>
                        </button>
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("transfer")} >
                            <p data-e2e="transfer-upper-tab">Transfer</p>
                        </button>
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("fx")} >
                            <p data-e2e="fx-upper-tab">FX</p>
                        </button>
                        <button className='dashboardHeaderActions__button' disabled={readOnly} onClick={() => handleModalOpen("merchantTransfer")} >
                            <p data-e2e="merchant-upper-tab">Merchant</p>
                        </button>
                    </div>
                </div>
                <DashboardCurrencyDistribution />

                <BalanceFiatDeposit
                    open={modalAction === "deposit"}
                    onClose={() => setModalAction("")}
                    row={{}}
                />
                <BalanceFiatWithdraw
                    open={modalAction === "withdraw"}
                    onClose={() => setModalAction("")}
                    row={{}}
                    selectCurrency={true}
                />
                <BalanceFiatPaymentOut
                    open={modalAction === "paymentOut"}
                    onClose={() => setModalAction("")}
                    row={{}}
                    selectCurrency={true}
                />
                <BalanceFiatFx
                    open={modalAction === "fx"}
                    onClose={() => setModalAction("")}
                    row={{}}
                    selectCurrency={true}
                />
                <BalanceFiatTransfer
                    open={modalAction === "transfer"}
                    onClose={() => setModalAction("")}
                    row={{}}
                    selectCurrency={true}
                />
                <MerchantTransfer
                    open={modalAction === "merchantTransfer"}
                    onClose={() => setModalAction("")}
                    row={{}}
                    selectCurrency={true}
                />
            </div>
        </div>
    )
}

export default DashboardHeader