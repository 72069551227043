import React, { useEffect, useState } from 'react'
import Icon from '../../icons/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { toFixedTrunc, useJunoCommonFunctions } from '../../../helpers'
import { getCurrencySymbol } from '../../../helpers'
import { setErrorMessage } from '../../../../Redux/actions/junoAction'

export default function BalanceCard({ data, setActiveModal, setActiveData }) {
    const dispatch = useDispatch()
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const unfreezeBalances = useSelector((state) => state.juno.unfreezeFiatCurrencies)
    const clientData = useSelector((state) => state.juno.clientData)
    const readOnly =
        clientData && clientData.readOnly ? clientData.readOnly : false
    const { getBalances } = useJunoCommonFunctions();
    const openModal = async (modalName) => {
        try {
            const balances = await getBalances();
            const isAllowed = balances.some(balance =>
                balance.currencyShortName === data.currencyShortName && !balance.isFreeze
            );
            const currentUnfreezeBalances = balances.filter(balance => !balance.isFreeze)
            const canOpenModal =
                (isAllowed && modalName !== 'fx') ||
                (modalName === 'fx' && isAllowed && currentUnfreezeBalances.length >= 2);
            if (canOpenModal) {
                setActiveData(data);
                setActiveModal(modalName);
            } else if (
                isAllowed
                && currentUnfreezeBalances.length < 2
                && modalName === 'fx'
            ) {
                dispatch(setErrorMessage(
                    "Currency exchange requires multiple available currencies. Please contact your administrator to add another currency and proceed with the FX transaction."
                ));
            } else {
                dispatch(setErrorMessage(
                    "This currency is temporarily unavailable. Please contact your administrator for further assistance."
                ));
            }
        } catch (error) {
            dispatch(setErrorMessage(
                'Failed to retrieve balance information. Please try again later.'
            ));
        }
    };

    const balanceAmount = toFixedTrunc(data.balanceAmount, 2)
        .toString()
        .split('.')

    return (
        <div className="jncBalanceCard jncCard">
            <div className="jncBalanceCard__curr">
                <div>
                    <p className="min-768 jncBalanceCard__curr-value">
                        <span
                            data-e2e-currency={`${getCurrencySymbol(data.currencyShortName)}`}
                            data-e2e={`${balanceAmount[0]}`}
                            data-e2e-amountdecimal={`${balanceAmount[1]}`}
                        >
                            {getCurrencySymbol(data.currencyShortName)}&nbsp;
                        </span>
                        {balanceAmount[0]} <sup>.{balanceAmount[1]}</sup>
                    </p>
                    <p className="max-767 jncBalanceCard__curr-value">
                        {toFixedTrunc(data.balanceAmount, 2)}{' '}
                        {data.currencyShortName}
                    </p>
                    <p className="jncBalanceCard__curr-percent" data-e2e={toFixedTrunc(data.holdingPercentage, 2)} data-e2e-percentage={data.currencyShortName}>
                        {toFixedTrunc(data.holdingPercentage, 2)}%
                    </p>
                </div>
                <div className="jncBalanceCard__curr-img">
                    <img src={`${imageBaseUrl}${data.currencyIcon}`} />
                    <p className="max-767">{data.currencyFullName}</p>
                </div>
            </div>
            <div className="jncBalanceCard__actions">
                <button
                    data-e2e={`${data.currencyFullName}-deposit`}
                    onClick={() => openModal('deposit')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Deposit</span>
                    <Icon id="deposit" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-withdraw`}
                    onClick={() => openModal('withdraw')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Withdrawal</span>
                    <Icon id="withdrawal" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-third-party-payment`}
                    onClick={() => openModal('paymentOut')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Third-party payment</span>
                    <Icon id="paymentOut" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-transfer`}
                    onClick={() => openModal('transfer')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn  jncActionBtn-transfer  jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>Transfer</span>
                    <Icon id="transfer" />
                </button>
                <button
                    data-e2e={`${data.currencyFullName}-fx`}
                    onClick={() => openModal('fx')}
                    className={`${readOnly && 'jnc-btn-disable'} jncActionBtn jncHint jncBalanceCard__actions-item`}
                    disabled={readOnly}
                >
                    <span>FX</span>
                    <Icon id="fx" />
                </button>
            </div>
        </div>
    )
}
